import React, { useEffect, useState } from "react";
import "./css/LoginDM.css";
import img from "../../Assets/login.png";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, signupVerify } from "../../redux/user";
import { toast } from "react-toastify";
import us from "../../Assets/Us.svg";
import { devApi } from "../../utils/constants";
import axios from "axios";
import { Modal } from "react-bootstrap";
import forgotmodal from "../../Assets/forgotmodal.png";
const regForEmail = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
);

export default function DMDetails() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logindata, setLogindata] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    phone: "",
  });
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [unverifiedUser, setUnverifiedUser] = useState();
  const [showVerifyUserLink, setShowVerifyUserLink] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const [signupdata, setSignupdata] = useState({
    first_name: user.first_name ? user.first_name : "",
    last_name: user.last_name ? user.last_name : "",
    phone: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [dmId, setDmId] = useState();

  const updateData = (e) => {
    let { name, value } = e.target;
    setSignupdata({
      ...signupdata,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    switch (name) {
      case "first_name":
        setErrors({
          ...errors,
          first_name: value.length === 0 ? "First name is required" : "",
        });
        break;
      case "last_name":
        setErrors({
          ...errors,
          last_name: value.length === 0 ? "Last name is required" : "",
        });
        break;
      default:
        break;
    }
  };
  const checkError = () => {
    const newErrors = { ...errors };
    let dataIsValid = true;
    if (!signupdata.first_name) {
      dataIsValid = false;
      newErrors.first_name = "Email";
    } else if (!regForEmail.test(logindata.email)) {
      dataIsValid = false;
      newErrors.email = "Invalid email";
    } else {
      newErrors.email = "";
    }
    if (!dataIsValid) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const logins = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        };
        const body = {
          first_name: signupdata.first_name,
          last_name: signupdata.last_name,
          phone: signupdata.phone,
        };
        await axios
          .post(`${devApi}/api/dm/doorManagerDetails`, body, config)
          .then((res) => {
            if (res.data.success) {
              setShowModal(true);
              setSignupdata({
                ...signupdata,
                first_name: "",
                last_name: "",
                phone: "",
              });
            } else {
              setErrors({ ...errors, credentials: res.data.message });
            }
          })
          .catch((error) => {
            if (error.response.status === 451) {
              setUnverifiedUser(error.response.data.user);
              setShowVerifyUserLink(true);
            }
            setErrors({
              ...errors,
              credentials: error.response.data.message,
            });
          });
      } catch (error) {
        setErrors({ ...errors, credentials: error.response.data.message });
      } finally {
        setLoading(false);
      }
    }, 1000);
  };
  const handleVerfiyEmailNow = () => {
    navigate(`/verifyemail`, {
      state: { ...unverifiedUser },
    });
    dispatch(signupVerify({}));
  };

  const handleDm = () => {
    navigate("/doormanagement");
  };
  const handleSuccessModal = () => {
    setShowModal(false);
    navigate("/scanner");
  };

  return (
    <div className="root-container d-flex align-items-center justify-content-center mt-10">
      <div className="logindm-page">
        <Link to="/">
          <img src={eventgemLogo} alt="" />
        </Link>
        <p className="text-center">
          Provide your information to start door management for{" "}
          <small className="title-dupage">DuPage County Fair</small>
        </p>
        <div className="form-box w-100">
          {/*First Name Last Name*/}
          <div className="d-flex  mb-2 mt-2">
            <div
              className="d-inline flex-column mb-2 "
              style={{ width: "50%" }}
            >
              <label htmlFor="" className="signup-label">
                First Name<small style={{ color: "red" }}>*</small>
              </label>
              <input
                type="text"
                className="signup-input-name"
                placeholder="Enter First Name"
                name="first_name"
                onChange={updateData}
                value={signupdata.first_name}
                disabled={user?.first_name}
              />
              <small className="text-danger ml-2">{errors.first_name}</small>
            </div>
            <div
              className="d-inline flex-column mb-2 ms-2"
              style={{ width: "50%" }}
            >
              <label for="formLastName" className="signup-label">
                Last Name<small style={{ color: "red" }}>*</small>
              </label>
              <input
                type="text"
                id="formLastName"
                className="signup-input-name"
                placeholder="Enter Last Name"
                name="last_name"
                onChange={updateData}
                value={signupdata.last_name}
                disabled={user?.last_name}
              />
              <small className="text-danger ml-2">{errors.last_name}</small>
            </div>
          </div>

          {/*Mobile Number*/}
          <div className="mb-2">
            <label htmlFor="" className="signup-label">
              Mobile Number
            </label>
            <div className="d-flex">
              {" "}
              <div
                className="form-input4 text-center align-items-center"
                style={{ padding: "12px 12px" }}
              >
                <img
                  src={us}
                  alt=""
                  className="img-fluid flag-img me-1"
                  style={{
                    width: "24px",
                    height: "23px",
                    marginBottom: 0,
                  }}
                />{" "}
                <small className="me-1 align-items-center">+1</small>
              </div>
              <input
                id="phone"
                name="phone"
                type="tel"
                className="signup-input ms-1"
                onChange={updateData}
                value={signupdata.phone}
                placeholder="Enter your mobile number"
              />
            </div>
            <small className="text-danger ml-2">{errors.phone}</small>
          </div>
        </div>
        {loading === true ? (
          <div className="d-flex justify-content-center align-items-center">
            <button className="login-btn" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        ) : (
          <button
            className={
              signupdata.first_name === "" || signupdata.last_name === ""
                ? "login-btn-disable"
                : "login-btn"
            }
            onClick={logins}
          >
            Proceed
          </button>
        )}
      </div>
      <Modal show={showModal} onHide={handleSuccessModal} centered size="sm">
        <Modal.Body>
          <div className="forgot-modal">
            <h2>Welcome to</h2>
            <div className="d-flex justify-content-center">
              <img
                src={eventgemLogo}
                alt=""
                className=""
                style={{ width: 300, height: 150 }}
              />
            </div>
            <p className="text-center">
              You are door manager for{" "}
              <small className="title-dupage">DuPage County Fair</small>
            </p>
            <div className="d-flex justify-content-center">
              <img src={forgotmodal} alt="" className="img-fluid" />
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary my-2"
                onClick={handleSuccessModal}
              >
                Initiate Scanning
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
