import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setEventDetails } from "../../redux/ticket";
import { updateEventId } from "../../redux/user";
import { devApi } from "../../utils/constants";
import axios from "axios";
import DupageBanner from "./DupageBanner";
import DupageTickets from "./DupageTickets";
import { useParams } from "react-router-dom";

export default function DupageHomepage() {
  const { inputValue } = useParams();
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(false);
  const ticketSummaryRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateEventId(29));
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(
          `${devApi}/api/e/fetchEventDetails?event_id=${inputValue}`
        );
        setEventData(response.data);

        dispatch(setEventDetails(response.data));
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchEventDetails();
  }, []);

  const getLoadFromTicket = (loading) => {
    setLoading(loading);
  };
  if (!eventData) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  if (loading) {
    return (
      <div className="d-flex flex-column justify-content-center text-center align-items-center ndf">
        <span className="redirect-text ">
          You are being redirected to the secured payment gateway
        </span>
        <span>Do not click the "Back" button!</span>
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <>
      {eventData.data ? (
        <>
          {" "}
          <DupageBanner ticketOrder={ticketSummaryRef} data={eventData} />
          <DupageTickets
            ticketOrder={ticketSummaryRef}
            data={eventData}
            getLoadFromTicket={getLoadFromTicket}
          />
        </>
      ) : (
        <div className="container ">
          <div
            className="row d-flex align-items-center justify-content-center"
            style={{ height: "100vh" }}
          >
            <div className="col-lg-6">
              <h1 className="text-center">No data Found</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
