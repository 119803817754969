import { useEffect, useRef, useState } from "react";

// Styles
import "./css/QrScanner.css";

// Qr Scanner
import QrScanner from "qr-scanner";
import QrFrame from "../../Assets/qr-frame.svg";
import localpass from "../../Assets/localpass.png";
import axios from "axios";
import { devApi } from "../../utils/constants";
import { Modal } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import closeIcon from "../../Assets/close.svg";
import qrIcon from "../../Assets/qrIcon.svg";
import { IoIosArrowForward } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";
import ticketNumber from "../../Assets/ticketNumber.svg";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import { DiVisualstudio } from "react-icons/di";
import userPhoto from "../../Assets/userPhoto.png";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// "#D9D9D9"
// "#024DDF"
const DMOptions = () => {
  const navigate = useNavigate();
  // QR States
  const scanner = useRef();
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);
  const [videoElCurrent, setVideoElCurrent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [attendeeData, setAttendeeData] = useState();
  const [message, setMessage] = useState();
  const [result, setResult] = useState(false);
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  // Result
  const [scannedResult, setScannedResult] = useState("");

  // Success
  const onScanSuccess = async (result) => {
    scanner.current.stop();
    setScannedResult(result?.data);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      first_name: user.first_name,
      last_name: user.last_name,
    };

    await axios
      .post(`${devApi}/api/dm/scanAttendeeQR`, body, config)
      .then((response) => {
        if (response.data.success) {
          setAttendeeData(response.data.attendee);
          setMessage();
          setShowModal(true);
          setResult(true);
        } else {
          setAttendeeData();
          setResult(false);
          setShowModal(true);
          setMessage(response.data.message);
          scanner.current.stop();
        }
      })
      .catch((error) => {});
  };

  // Fail
  const onScanFail = (err) => {};
  const scan = () => {
    scanner.current.start();
    setShowModal(false);
  };
  // const detectDevice = async () => {
  //   navigator.bluetooth
  //     .requestDevice({
  //       filters: [{ name: "BarCode Scanner BLE" }],
  //       services: ["0000180f-0000-1000-8000-00805f9b34fb"], // Required to access service later.
  //     })
  //     .then((device) => {
  //       console.log("device", device);
  //       return device.gatt?.connect();
  //     })
  //     .then((server) => {
  //       console.log("server", server);
  //       return server.getPrimaryService("0000180f-0000-1000-8000-00805f9b34fb");
  //     })
  //     .then((service) => {
  //       console.log("sevice", service);
  //       return service.getCharacteristic(
  //         "0000180f-0000-1000-8000-00805f9b34fb-0x1200e3bb4a"
  //       );
  //     })
  //     .then((characteristic) => {
  //       // Reading Battery Level…
  //       return characteristic.readValue();
  //     })
  //     .then((value) => {
  //       console.log(`QR ${value.getUint8(0)}`);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  useEffect(() => {
    const fetchDMDetails = async () => {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const body = {
        first_name: user.first_name,
        last_name: user.last_name,
      };

      await axios
        .post(`${devApi}/api/dm/doorManagerDetails`, body, config)
        .then((response) => {
          if (response.data.success) {
            setAttendeeData(response.data.attendee);
            setMessage();
            setShowModal(true);
            setResult(true);
          } else {
            setAttendeeData();
            setResult(false);
            setShowModal(true);
            setMessage(response.data.message);
            scanner.current.stop();
          }
        })
        .catch((error) => {});
    };
    fetchDMDetails();
  }, []);

  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  return (
    <div className="qr-reader d-flex flex-column align-items-center justify-content-center">
      <button
        className="btn btn-primary"
        onClick={() => navigate("/camerascanner")}
      >
        {" "}
        Use Device's Camera
      </button>
    </div>
  );
};

export default DMOptions;
