import { useEffect, useRef, useState } from "react";

// Styles
import "./css/BluetoothDevice.css";

// Qr Scanner
import QrScanner from "qr-scanner";
import QrFrame from "../../Assets/qr-frame.svg";
import localpass from "../../Assets/localpass.png";
import axios from "axios";
import { devApi } from "../../utils/constants";
import { Modal } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import closeIcon from "../../Assets/close.svg";
import qrIcon from "../../Assets/qrIcon.svg";
import { IoIosArrowForward } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";
import ticketNumber from "../../Assets/ticketNumber.svg";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import { MdOutlineQrCodeScanner, MdClose } from "react-icons/md";
import { LuTicket } from "react-icons/lu";
import { DiVisualstudio } from "react-icons/di";
import userPhoto from "../../Assets/userPhoto.png";
import moment from "moment";
import onScan from "onscan.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSymbologyScanner } from "@use-symbology-scanner/react";
import BarcodeReader from "react-barcode-reader";

// "#D9D9D9"
// "#024DDF"
const DMBluetoothDevice = () => {
  const navigate = useNavigate();
  // QR States
  const scanner = useRef();
  const [showTicketCountLoad, setShowTicketCountLoad] = useState(false);
  const [loadingResult, setLoadingResult] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [attendeeData, setAttendeeData] = useState();
  const [message, setMessage] = useState();
  const [result, setResult] = useState(false);
  const [initialize, setInitialize] = useState(false);
  const token = useSelector((state) => state.user.token);
  // Result
  const [scannedResult, setScannedResult] = useState("");
  const ref = useRef(null);
  const onScanSuccess = async (result) => {
    setLoadingResult(true);
    setScannedResult(result);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      qr_code: result,
    };

    await axios
      .post(`${devApi}/api/dm/scanAttendeeQR`, body, config)
      .then((response) => {
        if (response.data.success) {
          setAttendeeData(response.data.attendee);
          setMessage();
          setShowModal(true);
          setResult(true);
          setLoadingResult(false);
        } else {
          setAttendeeData();
          setResult(false);
          setShowModal(true);
          setMessage(response.data.message);
          setLoadingResult(false);
        }
      })
      .catch((error) => {
        setLoadingResult(false);
      });
  };

  //   if (!onScan.isAttachedTo(document)) {
  //     onScan.attachTo(document, {
  //       suffixKeyCodes: [13], // enter-key expected at the end of a scan
  //       reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
  //       onScan: async (result, oty) => {
  //         setLoadingResult(true);
  //         console.log("success", result);
  //         setScannedResult(result);
  //         const config = {
  //           headers: {
  //             "Content-type": "application/json",
  //             Authorization: "Bearer " + token,
  //           },
  //         };
  //         const body = {
  //           qr_code: result,
  //         };

  //         await axios
  //           .post(`${devApi}/api/dm/scanAttendeeQR`, body, config)
  //           .then((response) => {
  //             if (response.data.success) {
  //               setAttendeeData(response.data.attendee);
  //               setMessage();
  //               setShowModal(true);
  //               setResult(true);
  //               setLoadingResult(false);
  //             } else {
  //               setAttendeeData();
  //               setResult(false);
  //               setShowModal(true);
  //               setMessage(response.data.message);
  //               setLoadingResult(false);
  //             }
  //           })
  //           .catch((error) => {
  //             setLoadingResult(false);
  //           });
  //       },
  //       onKeyDetect: function (iKeyCode) {
  //         // output all potentially relevant key events - great for debugging!
  //         console.log("Pressed: " + iKeyCode);
  //       },
  //     });
  //   }
  //   console.log(onScan.decodeKeyEvent("keydown"));
  // Success

  const getTicketCount = async () => {
    setShowTicketCountLoad(true);

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };

    await axios
      .post(`${devApi}/api/dm/doorManagerDashboard`, null, config)
      .then((response) => {
        if (response.data.success) {
          //   setAttendeeData(response.data.attendee);
          //   setMessage();
          //   setShowModal(true);
          //   setResult(true);
          //   setLoadingResult(false);
        } else {
          //   setAttendeeData();
          //   setResult(false);
          //   setShowModal(true);
          //   setMessage(response.data.message);
          //   setLoadingResult(false);
        }
      })
      .catch((error) => {
        setLoadingResult(false);
      });
  };
  const scan = () => {
    setShowModal(false);
    // initializeScan();
  };
  const failed = (e) => {
    // console.log("eve", e);
  };

  //   const initializeScan = () => {
  //     console.log("Attached", onScan.isAttachedTo(document));
  //     if (!onScan.isAttachedTo(document)) {
  //       onScan.attachTo(document, {
  //         suffixKeyCodes: [13], // enter-key expected at the end of a scan
  //         reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
  //         //onScan: onScanSuccess,
  //       });
  //       setInitialize(true);
  //       console.log("Attached 2", onScan.isAttachedTo(document));
  //     } else {
  //     }
  //   };

  return (
    <div className="qr-reader d-flex flex-column align-items-center justify-content-center">
      <div ref={ref}></div>
      <div className="navbar fixed-top navbar-light bg-light justify-content-between">
        <div className="m-2 mx-lg-4">
          <img
            src={eventgemLogo}
            alt="Company Logo"
            className="img-fluid "
            fetchpriority="high"
          />
        </div>
        <div className="m-2 mx-lg-4">
          <span className="option-text">Hi</span>{" "}
          <img src={userPhoto} alt="" className="img-fluid user-nav-img " />{" "}
        </div>
      </div>
      {/* QR */}
      {initialize ? (
        <div className="d-flex flex-column align-items-center justify-content-center">
          {loadingResult ? (
            <div className="loader-box">
              <span></span>
              <span></span>
            </div>
          ) : !showModal ? (
            <message className="ble-message p-2 m-2">
              Ready for scanning{" "}
            </message>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <message className="ble-message p-2 m-2 text-center">
            Make sure your device is connected to a bluetooth HID{" "}
          </message>
          <button
            className="btn btn-primary mt-4"
            onClick={setInitialize(true)}
          >
            {" "}
            Start Scan{" "}
          </button>
        </div>
      )}
      <BarcodeReader onError={failed} onScan={onScanSuccess} />
      <div className="navbar fixed-bottom navbar-light bg-light  justify-content-around ">
        <div className="d-flex flex-column align-items-center justify-content-center mx-2">
          <LuTicket
            size={24}
            color={showTicketCountLoad ? "#024DDF" : ""}
            onClick={getTicketCount}
          />
          <h5 className="option-text">Ticket Status</h5>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center mx-2">
          <MdOutlineQrCodeScanner
            size={24}
            onClick={() => {
              onScan.detachFrom(document);
              setInitialize(false);
              navigate("/camerascanner");
            }}
          />
          <h5 className="option-text">Use Camera</h5>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center mx-2">
          <MdClose size={24} />
          <h5 className="option-text">Close Entries</h5>
        </div>
      </div>

      {showModal ? (
        <div className="d-flex justify-content-between  flex-column align-items-center mb-2 ">
          <div className="overlayScan">
            <div className="">
              {result ? (
                <>
                  <div className="d-flex flex-row justify-content-between align-items-center mx-3 my-2 result-header">
                    <div className="d-flex  justify-items-center align-items-center ">
                      <FaRegCheckCircle size={24} color="#1EA133" />{" "}
                      <h5 className="successScan ms-2">Success</h5>
                    </div>
                    <button
                      className="my-2 btn btn-outline-secondary justify-items-center align-items-center"
                      style={{ borderRadius: 20 }}
                      onClick={scan}
                    >
                      <small className="resultButtons">Scan Next</small>
                      <IoIosArrowForward />
                    </button>
                  </div>
                  <div className="d-flex  flex-column mx-4">
                    <div className="d-flex justify-content-between flex-row align-items-center my-2">
                      <h5 className="attendee">
                        {attendeeData?.first_name} {attendeeData?.last_name}
                      </h5>
                      <small className="attendee-ticket">
                        {moment(attendeeData?.arrival_time).format("hh:mm A")}
                      </small>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <img
                          src={localpass}
                          alt=""
                          style={{ width: "20px", height: "20px" }}
                        />

                        <h5 className="attendee-ticket ms-2">
                          General Admission
                        </h5>
                      </div>

                      <div className="d-flex justify-content-between text-center align-items-center">
                        <div className="d-flex justify-content-between text-center align-items-center">
                          <h6 className="ticket-id">
                            Ticket Id : {attendeeData?.ticket_id}
                          </h6>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex flex-row justify-content-between align-items-center mx-3 my-2 result-header">
                  <div className="d-flex  justify-items-center align-items-center ">
                    <RxCrossCircled size={24} color="#FF4C32" />{" "}
                    <h5 className="failedScan ms-2">Failed</h5>
                  </div>
                  <button
                    className="my-2 btn btn-outline-secondary justify-items-center align-items-center"
                    style={{ borderRadius: 20 }}
                    onClick={scan}
                  >
                    <small className="resultButtons">Try Again</small>
                  </button>
                </div>
              )}
              {message && (
                <div className="d-flex justify-content-center flex-column align-items-center total-count">
                  <small className="text ml-2">{message}</small>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DMBluetoothDevice;
