import React from "react";
import "./css/Banner.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function DupageBanner({ data, ticketOrder }) {
  var settings = {
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="d-flex flex-column">
        <div className="col-lg-12 d-flex justify-content-between my-3"></div>
        <div className="col-lg-12 slide-main-box">
          <Slider className="dupage-banner-container" {...settings}>
            {data.data.banners?.map((image, index) => {
              return (
                <div className="slick-img">
                  <img
                    id={index}
                    src={image.banner_img_fullpath}
                    alt=""
                    className="img-fluid"
                    style={{ borderRadius: "20px" }}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center mb-lg-4">
          <div className="d-flex align-items-center mt-2">
            <h3 className="title">{data.data.event.title}</h3>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <h5 className="venue">Venue</h5>
            <p>
              {data.data.event.venue_name}{" "}
              {data.data.event.location_address_line_1},{" "}
              {data.data.event.location_address_line_2},{" "}
              {data.data.event.location_post_code}
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <h5 className="contact">Contact</h5>
            <p>630-668-6636</p>
          </div>
        </div>
      </div>
    </div>
  );
}
