import { createSlice } from "@reduxjs/toolkit";

const loadFromStorage = () => {
  try {
    const checkout = localStorage.getItem("checkout");
    return checkout;
  } catch (error) {
    console.error("Error loading state from localStorage:", error);
    return undefined;
  }
};
const initialState = {
  isLoggedIn: false,
  token: "",
  user: {},
  eventId: "",
  checkout: loadFromStorage(),
  signupDetails: {},
  lastVisited: "",
  dupageCounty: false,
  doorManagerLink: "",
};

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      localStorage.clear();
      state.isLoggedIn = true;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.eventId = action.payload.eventId;
    },
    signupVerify: (state, action) => {
      state.signupDetails = action.payload;
    },
    updateToken: (state, action) => {
      state.token = action.payload.token;
    },
    updateCheckout: (state, action) => {
      state.checkout = action.payload;
    },
    updateLastPage: (state, action) => {
      state.lastVisited = action.payload;
    },
    updateEventId: (state, action) => {
      state.eventId = action.payload;
    },
    isDupageCounty: (state, action) => {
      state.dupageCounty = action.payload;
    },
    logout: (state, action) => {
      localStorage.clear();
      state.isLoggedIn = false;
      state.token = "";
      state.user = {};
      state.eventId = "";
      state.checkout = false;
    },
    setDoorManagerLink: (state, action) => {
      state.doorManagerLink = action.payload;
    },
  },
});
export const {
  login,
  updateToken,
  logout,
  updateCheckout,
  signupVerify,
  updateLastPage,
  updateEventId,
  isDupageCounty,
  setDoorManagerLink,
} = authSlice.actions;
export default authSlice.reducer;
