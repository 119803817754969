import React from "react";
import "./Sidebar.css";
import { MdOutlineDashboard } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const SideBar = ({ opensidebar, setOpensidebar }) => {
  return (
    <div className={!opensidebar ? "AppSidebar" : "AppSidebar1"}>
      {!opensidebar ? (
        <div className="active-data">
          <MdOutlineDashboard />
        </div>
      ) : (
        <div className="active-data1">
          <MdOutlineDashboard />
          <p className="mb-0">Dashboard</p>
        </div>
      )}
      {!opensidebar ? (
        <div className="inactive-data">
          <FaCalendarAlt />
        </div>
      ) : (
        <div className="inactive-data1">
          <FaCalendarAlt />
          <p className="mb-0">Create Event</p>
        </div>
      )}

      <div className="position-relative">
        {!opensidebar ? (
          <div className="zoomin" onClick={() => setOpensidebar(true)}>
            <IoIosArrowForward color="#024DDF" size={20} />
          </div>
        ) : (
          <div className="zoomout" onClick={() => setOpensidebar(false)}>
            <IoIosArrowBack color="#024DDF" size={20} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
