import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { MdOutlineNotifications } from "react-icons/md";
import eventgemLogo from "../../../Assets/eventgemLogo.svg";
import userPhoto from "../../../Assets/userPhoto.png";
import { IoMdSearch } from "react-icons/io";
import { FaMoon } from "react-icons/fa6";

const Header = () => {
  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center mx-3 mt-3">
        <h1 className="logo me-auto">
          <Link to="/">
            <img
              src={eventgemLogo}
              alt="Company Logo"
              className="img-fluid"
              fetchpriority="high"
            />
          </Link>
        </h1>
        <nav>
          <ul>
            <li className="icon-link pe-4">
              <div className="search-inp-box">
                <IoMdSearch
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#024DDF",
                  }}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </li>
            <li className="icon-link pe-4">
              <MdOutlineNotifications
                style={{ width: "30px", height: "30px" }}
              />
            </li>
            <li className="icon-link pe-4">
              <FaMoon style={{ width: "30px", height: "28px" }} />
            </li>
            <li className="icon-link pe-4">
              <img
                src={userPhoto}
                alt=""
                className="img-fluid"
                style={{ width: "30px", height: "30px" }}
              />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
