import React, { useEffect, useState } from "react";
import "./css/Login.css";
import img from "../../Assets/login.png";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import { TbBrandGoogle } from "../../Assets/google_color.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, signupVerify } from "../../redux/user";
import { toast } from "react-toastify";
import { devApi } from "../../utils/constants";
import axios from "axios";
const regForEmail = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
);

export default function Login() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const inputValue = urlParams.get("inputValue") || 21;
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logindata, setLogindata] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    credentials: "",
  });
  const [loginUrlGoogle, setLoginUrlGoogle] = useState(null);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [unverifiedUser, setUnverifiedUser] = useState();
  const [showVerifyUserLink, setShowVerifyUserLink] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const updateData = (e) => {
    let { name, value } = e.target;
    setLogindata({
      ...logindata,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    switch (name) {
      case "email":
        setErrors({
          ...errors,
          email: value.length === 0 ? "Email is required" : "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password: value.length === 0 ? "Password is required" : "",
        });
        break;
      default:
        break;
    }
  };
  const checkError = () => {
    const newErrors = { ...errors };
    let dataIsValid = true;
    if (!logindata.email) {
      dataIsValid = false;
      newErrors.email = "Email is required";
    } else if (!regForEmail.test(logindata.email)) {
      dataIsValid = false;
      newErrors.email = "Invalid email";
    } else {
      newErrors.email = "";
    }
    if (!dataIsValid) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const logins = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        if (!checkError()) {
          return;
        }
        await axios
          .post(`${devApi}/api/clientLogin`, logindata)
          .then((res) => {
            if (res.data.success) {
              dispatch(
                login({
                  token: res.data.token,
                  user: res.data.user,
                  eventId: inputValue,
                })
              );
              navigate(`/`);
              toast.success("Login Successful");
            } else {
              setErrors({ ...errors, credentials: res.data.message });
            }
          })
          .catch((error) => {
            if (error.response.status === 451) {
              setUnverifiedUser(error.response.data.user);
              setShowVerifyUserLink(true);
            }
            setErrors({
              ...errors,
              credentials: error.response.data.message,
            });
          });
      } catch (error) {
        setErrors({ ...errors, credentials: error.response.data.message });
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  const handleDm = () => {
    navigate("/doormanagement");
  };
  useEffect(() => {
    const handlePopState = (e) => {
      window.history.go(1);
    };

    // Add event listener on mount
    window.addEventListener("popstate", handlePopState);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  useEffect(() => {
    fetch(`${devApi}/api/googleAuthLogin`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setLoginUrlGoogle(data.url))
      .catch((error) => console.error(error));
  }, []);
  // useEffect(() => {

  return (
    <div className="login-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-ms-12 col-12 head-box">
            <h3>
              Catch the coolest events in your town right here, right now!
            </h3>
            <img src={img} alt="" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-md-12 col-ms-12 col-12 login-page">
            <Link to="/">
              <img src={eventgemLogo} alt="" />
            </Link>
            <h6>Welcome to hassle-free event bookings!</h6>
            <p>Log into your account</p>
            <div className="d-flex justify-content-center align-items-center mb-4 w-25">
              {loginUrlGoogle ? (
                <a href={loginUrlGoogle}>
                  <TbBrandGoogle style={{ width: "32px", height: "30px" }} />
                </a>
              ) : (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </div>
            <div className="d-flex justify-content-evenly align-items-center w-100">
              <div className="left-line"></div>
              <h6 className="or">Or</h6>
              <div className="right-line"></div>
            </div>

            <div className="form-box w-100">
              {errors.credentials && (
                <small className={"text-danger ml-2"}>
                  {errors.credentials}
                </small>
              )}
              {showVerifyUserLink && (
                <Link to="/verifyemail" state={{ ...unverifiedUser }}>
                  <small className={"text-primary ms-2"}>
                    Verify Email Now?
                  </small>
                </Link>
              )}
              <div className="mb-2">
                <label htmlFor="" className="label">
                  Email
                </label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="Enter Email"
                  name="email"
                  onChange={updateData}
                  value={logindata.email}
                />
                <small className="text-danger ml-2">{errors.email}</small>
              </div>
              <div className="mb-2">
                <label htmlFor="" className="label">
                  Password
                </label>
                <div className="d-flex align-items-center signup-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="signup-input-password"
                    placeholder="Enter Password"
                    name="password"
                    onChange={updateData}
                    value={logindata.password}
                  />
                  <span
                    className="show-hide"
                    id="showHide"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <i className="bi bi-eye show-hide"></i>
                    ) : (
                      <i
                        className="bi bi-eye-slash show-hide"
                        id="togglePassword"
                      ></i>
                    )}
                  </span>
                </div>
                <small className="text-danger ml-2">{errors.password}</small>
              </div>
              <Link to="/forgotpassword">
                <h6 className="forget-text">Forgot password?</h6>
              </Link>
            </div>
            {loading === true ? (
              <div className="d-flex justify-content-center align-items-center">
                <button className="login-btn" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            ) : (
              <button
                className={
                  logindata.email === "" || logindata.password === ""
                    ? "login-btn-disable"
                    : "login-btn"
                }
                onClick={logins}
              >
                Login
              </button>
            )}

            <h5>
              Don't have an account?{" "}
              <Link to="/signup">
                <span>Sign Up</span>
              </Link>
            </h5>
            {/*<div className="d-flex flex-column my-4 justify-content-evenly align-items-center w-100">
              <div className="line"></div>
              <div className="my-4">
                <button className=" door-management-btn" onClick={handleDm}>
                  Door Management
                </button>
              </div>
          </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
}
